/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "align-content",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#align-content",
    "aria-label": "align content permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Align Content"), "\n", React.createElement(_components.p, null, "Utilities for controlling how rows are positioned in multi-row flex and grid containers."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "alignContent={keyword}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "align-content: {keyword};"))))), "\n", React.createElement(_components.h2, {
    id: "start",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#start",
    "aria-label": "start permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Start"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "alignContent=\"flex-start\""), " to pack rows in a container against the start of the cross axis:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=red"
  }, "<>\n  <template preview>\n    <x.div\n      h={48}\n      display=\"flex\"\n      flexWrap=\"wrap\"\n      alignContent=\"flex-start\"\n      bg=\"red-200\"\n      borderRadius=\"md\"\n    >\n      {Array.from({ length: 5 }, (_, index) => index + 1).map((value) => (\n        <x.div key={value} w={1 / 3} p={2}>\n          <x.div\n            p={2}\n            borderRadius=\"md\"\n            bg=\"red-500\"\n            color=\"white\"\n            display=\"flex\"\n            alignItems=\"center\"\n            justifyContent=\"center\"\n            fontSize=\"2xl\"\n            fontWeight=\"extrabold\"\n          >\n            {value}\n          </x.div>\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div h={48} display=\"flex\" flexWrap=\"wrap\" alignContent=\"flex-start\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n    <div>4</div>\n    <div>5</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "center",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#center",
    "aria-label": "center permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Center"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "alignContent=\"center\""), " to pack rows in a container in the center of the cross axis:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=indigo"
  }, "<>\n  <template preview>\n    <x.div\n      h={48}\n      display=\"flex\"\n      flexWrap=\"wrap\"\n      alignContent=\"center\"\n      bg=\"indigo-200\"\n      borderRadius=\"md\"\n    >\n      {Array.from({ length: 5 }, (_, index) => index + 1).map((value) => (\n        <x.div key={value} w={1 / 3} p={2}>\n          <x.div\n            p={2}\n            borderRadius=\"md\"\n            bg=\"indigo-500\"\n            color=\"white\"\n            display=\"flex\"\n            alignItems=\"center\"\n            justifyContent=\"center\"\n            fontSize=\"2xl\"\n            fontWeight=\"extrabold\"\n          >\n            {value}\n          </x.div>\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div h={48} display=\"flex\" flexWrap=\"wrap\" alignContent=\"center\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n    <div>4</div>\n    <div>5</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "end",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#end",
    "aria-label": "end permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "End"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "alignContent=\"flex-end\""), " to pack rows in a container against the end of the cross axis:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div\n      h={48}\n      display=\"flex\"\n      flexWrap=\"wrap\"\n      alignContent=\"flex-end\"\n      bg=\"emerald-200\"\n      borderRadius=\"md\"\n    >\n      {Array.from({ length: 5 }, (_, index) => index + 1).map((value) => (\n        <x.div key={value} w={1 / 3} p={2}>\n          <x.div\n            p={2}\n            borderRadius=\"md\"\n            bg=\"emerald-500\"\n            color=\"white\"\n            display=\"flex\"\n            alignItems=\"center\"\n            justifyContent=\"center\"\n            fontSize=\"2xl\"\n            fontWeight=\"extrabold\"\n          >\n            {value}\n          </x.div>\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div h={48} display=\"flex\" flexWrap=\"wrap\" alignContent=\"flex-end\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n    <div>4</div>\n    <div>5</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "space-between",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#space-between",
    "aria-label": "space between permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Space between"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "alignContent=\"space-between\""), " to distribute rows in a container such that there is an equal amount of space between each line:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=amber"
  }, "<>\n  <template preview>\n    <x.div\n      h={48}\n      display=\"flex\"\n      flexWrap=\"wrap\"\n      alignContent=\"space-between\"\n      bg=\"amber-200\"\n      borderRadius=\"md\"\n    >\n      {Array.from({ length: 5 }, (_, index) => index + 1).map((value) => (\n        <x.div key={value} w={1 / 3} p={2}>\n          <x.div\n            p={2}\n            borderRadius=\"md\"\n            bg=\"amber-500\"\n            color=\"white\"\n            display=\"flex\"\n            alignItems=\"center\"\n            justifyContent=\"center\"\n            fontSize=\"2xl\"\n            fontWeight=\"extrabold\"\n          >\n            {value}\n          </x.div>\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div h={48} display=\"flex\" flexWrap=\"wrap\" alignContent=\"space-between\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n    <div>4</div>\n    <div>5</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "space-around",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#space-around",
    "aria-label": "space around permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Space around"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "alignContent=\"space-around\""), " to distribute rows in a container such that there is an equal amount of space around each line:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div\n      h={48}\n      display=\"flex\"\n      flexWrap=\"wrap\"\n      alignContent=\"space-around\"\n      bg=\"light-blue-200\"\n      borderRadius=\"md\"\n    >\n      {Array.from({ length: 5 }, (_, index) => index + 1).map((value) => (\n        <x.div key={value} w={1 / 3} p={2}>\n          <x.div\n            p={2}\n            borderRadius=\"md\"\n            bg=\"light-blue-500\"\n            color=\"white\"\n            display=\"flex\"\n            alignItems=\"center\"\n            justifyContent=\"center\"\n            fontSize=\"2xl\"\n            fontWeight=\"extrabold\"\n          >\n            {value}\n          </x.div>\n        </x.div>\n      ))}\n    </x.div>\n  </template>\n  <x.div h={48} display=\"flex\" flexWrap=\"wrap\" alignContent=\"space-around\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n    <div>4</div>\n    <div>5</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the alignment of flex content at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "alignContent={{ md: \"center\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "alignContent=\"center"), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div display=\"grid\" alignContent={{ md: 'center' }}>\n  {/* ... */}\n</x.div>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
